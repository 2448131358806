<template>
  <div>
    <el-form :model="form"
             ref="formRef"
             label-width="100px">
      <el-row :gutter="10"
              style="padding-bottom:10px">
        <el-col :span="6">
          <el-form-item label="教师id">
            <el-input placeholder="请输入教师id"
                      style="width:100%"
                      v-model="form.edu_user_id" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="用户名/姓名">
            <el-input placeholder="请输入用户名/姓名"
                      style="width:100%"
                      v-model="form.edu_user_name" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="开始时间">
            <el-date-picker v-model="form.begin_time"
                            type="datetime"
                            style="width:100%"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择开始时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="截止时间">
            <el-date-picker v-model="form.end_time"
                            type="datetime"
                            default-time="23:59:59"
                            style="width:100%"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择截止时间" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-button type="primary"
                     @click="search">提交</el-button>
          <el-button type="primary"
                     @click="exportExcel">导出</el-button>
        </el-col>
      </el-row>
    </el-form>
    <Table ref="TablesRef"
           :tableData="tableData" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import { now_month_time, nowDateTime, frontNowDay } from '@/utils/date.js'

export default {
  data () {
    return {
      tableData: [],
      options: [],
      form: {
        edu_user_id: '',
        edu_user_name: '',
        // begin_time: '',
        // end_time: ''
        begin_time: now_month_time(),
        end_time: frontNowDay(0)
      },
      page: 1,
      size: 10,
      totalElements: 0,
      pageName: 'lutiAdmin_admin_topic',
      // 所有教师的教师ID、用户别名、用户名、录入试卷数、录入题目数、修改题目数、校对试卷套数、校对试卷考点套数、校对试卷知识点套数、校对试卷章节套数
      tableColumns: [
        { prop: 'ID', align: 'center', label: '教师ID', width: '', showToopic: false },
        { prop: 'Username', align: 'center', label: '用户别名', width: '', showToopic: false },
        { prop: 'UserTrueName', align: 'center', label: '用户名', width: '', showToopic: false },
        { prop: 'paperCount', align: 'center', label: '录入试卷数', width: '', showToopic: false },
        { prop: 'questionCount', align: 'center', label: '录入题目数', width: '', showToopic: false },
        { prop: 'editCount', align: 'center', label: '修改题目数', width: '', showToopic: false },
        { prop: 'checkCount', align: 'center', label: '校对试卷套数', width: '', showToopic: false },
        // { prop: 'pointCount', align: 'center', label: '校对试卷考点套数', width: '', showToopic: false },
        { prop: 'knowledgeCount', align: 'center', label: '校对试卷考点套数', width: '', showToopic: false },
        { prop: 'chapterCount', align: 'center', label: '校对试卷章节套数', width: '', showToopic: false },
        { prop: 'errorCount', align: 'center', label: '录入错误数', width: '', showToopic: false },
        {
          prop: 'd', align: 'center', label: '操作', width: '', type: 'img', btns: [
            { content: '录入详情', type: 'look', event: 'showForm' },
          ]
        },
      ]
    }
  },
  mounted () {
    this.setTablesColums()
  },
  methods: {
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
    initData (page, limit) {
      let form = JSON.parse(JSON.stringify(this.form))
      form.page = page
      form.limit = limit
      this.$http({
        url: '/api/v2/stats/record',
        method: 'get',
        params: form
      }).then(res => {
        console.log('res', res)
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    showForm (row) {
      let form = {
        begin_time: this.form.begin_time,
        end_time: this.form.end_time,
        edu_user_id: row.ID
      }

      this.$router.push('/topicAdmin/usertopicList?form=' + JSON.stringify(form))
    },
    exportExcel () {
      this.$http({
        url: '/api/v2/stats/record_export',
        method: 'get',
        responseType: "blob",
        params: this.form
      }).then(res => {
        console.log('res', res);
        const a = document.createElement("a")
        a.href = window.URL.createObjectURL(res.data)
        a.download = `录题统计${this.form.begin_time}${'-' + this.form.begin_time}.xlsx`
        a.click()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>